import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This tutorial walks you through how to create a Custom Audience on Facebook using Dashbot’s Audience Builder. We will be creating a custom audience for our movie chatbot that is designed to sell movie tickets. Our custom audience will be customers in the United States who have purchased a movie ticket.`}</p>
    <h3>{`Segment and Export User IDs From Dashbot`}</h3>
    <p>{`1`}{`.`}{` Login to your Dashbot account.`}</p>
    <p>{`2`}{`.`}{` On the left navigation bar under Audience, select `}<strong parentName="p">{`Audience Builder`}</strong>{`.`}</p>
    <p>{`3`}{`.`}{` Define audience criteria:`}</p>
    <ul>
      <li parentName="ul">{`Locale: en-US`}</li>
      <li parentName="ul">{`Outgoing Intents: Tickets_Purchased`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/06/20150810/TotalAudience-1024x303.png",
        "alt": null
      }}></img></p>
    <p>{`4`}{`.`}{` Select `}<strong parentName="p">{`Export CSV`}</strong>{`.`}</p>
    <h3>{`Create Custom Audience`}</h3>
    <p>{`5`}{`.`}{` Navigate to this page: `}<a parentName="p" {...{
        "href": "https://www.facebook.com/business/a/custom-audiences"
      }}>{`https://www.facebook.com/business/a/custom-audiences`}</a><br parentName="p"></br>{`
`}{`a.) Select `}<strong parentName="p">{`Create Audience`}</strong><br parentName="p"></br>{`
`}{`b.) Upload the CSV file you exported from Dashbot.`}<br parentName="p"></br>{`
`}{`c.) Map PlatformUserID correctly to Facebook Page User ID`}</p>
    <p>{`6`}{`.`}{` Select `}<strong parentName="p">{`Enter Facebook Page IDs.`}</strong></p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/06/20150659/FBPageInfo.png",
        "alt": null
      }}></img></p>
    <p>{`7`}{`.`}{` Retrieve your Facebook Page ID from `}<a parentName="p" {...{
        "href": "https://findmyfbid.com"
      }}>{`https://findmyfbid.com`}</a>{`.`}</p>
    <p>{`8`}{`.`}{` Wait for Hash `}{`&`}{` Upload.`}</p>
    <h3>{`Create Facebook Ads`}</h3>
    <p>{`9`}{`.`}{` Navigate to this page: `}<a parentName="p" {...{
        "href": "https://www.facebook.com/adsmanager"
      }}>{`https://www.facebook.com/adsmanager`}</a></p>
    <p>{`10`}{`.`}{` Select `}<strong parentName="p">{`Messages`}</strong>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/06/20151506/Messages-Ads-1024x460.png",
        "alt": null
      }}></img></p>
    <p>{`11`}{`.`}{` Choose the Custom Audience you just created and create an ad!`}</p>
    <h3>{`Dashbot’s Audience Builder is part of the Enterprise Plan so upgrade your account to find and target the right users. Learn more about the Enterprise Plan by contacting us: `}<a parentName="h3" {...{
        "href": "mailto:support@dashbot.io"
      }}>{`support@dashbot.io`}</a>{`.`}</h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      